<template>
  <div class="news-list-container">
    <div class="content">
      <el-table :data="sysConfig" border stripe>
        <el-table-column type="index" width="50"></el-table-column>
        <el-table-column
          v-for="col in columns"
          :key="col.id"
          :prop="col.id"
          :label="col.label"
          :width="col.width"
          :formatter="col.formatter"
        ></el-table-column>
        <el-table-column label="Action" width="250" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="handleEdit(scope.row)"
            >
              编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog
      :title="'修改'"
      :visible.sync="showCreateDialog"
      width="50%"
      @close="showCreateDialog = false"
    >
      <el-form ref="form" :model="postForm" label-width="80px">
        <el-form-item label="value">
          <el-input type="textarea" v-model="postForm.cfg_value"></el-input>
        </el-form-item>
        <el-form-item label="note">
          <el-input v-model="postForm.cfg_note"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="showCreateDialog = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { setSysConfig } from "@/api/configs";

export default {
  data() {
    return {
      showCreateDialog: false,
      postForm: {},
      columns: [
        {
          id: "cfg_key",
          label: "key",
        },
        {
          id: "cfg_value",
          label: "value",
        },
        {
          id: "cfg_note",
          label: "note",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["sysConfig"]),
  },
  mounted() {
    this.getSysConfig();
  },
  methods: {
    getSysConfig() {
      this.$store.dispatch("configs/getSysConfig");
    },
    handleEdit(row) {
      this.showCreateDialog = true;
      this.postForm = {
        id: row.id,
        cfg_value: row.cfg_value,
        cfg_note: row.cfg_note,
      };
    },
    onSubmit() {
      setSysConfig(this.postForm).then((data) => {
        if (+data.data.code) {
          this.$message({
            message: data.data.msg,
            duration: 3000,
            type: "error",
          });
        } else {
          this.$message({
            message: data.data.msg,
            duration: 3000,
            type: "success",
          });
        }
        this.handleSuccess();
      });
    },
    handleSuccess() {
      this.showCreateDialog = false;
      this.postForm = {};
      this.getSysConfig();
    },
  },
};
</script>
